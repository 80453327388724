@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2,
h3 {
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: var(--fw-700);
  font-size: clamp(1.875rem, 1.2917rem + 2.5926vi, 3.625rem);
  line-height: 1.21;
  letter-spacing: 0.02em;
  color: var(--color-green-900);

  span {
    display: inline-block;
    color: var(--color-green-400);
  }
}

h2 {
  margin-bottom: 16px;
  font-size: clamp(1.5rem, 1.125rem + 1.6667vi, 2.625rem);
  line-height: 1.3;
  letter-spacing: 0.03em;
  text-align: center;
}

h3 {
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0;
}

p {
  opacity: 0.4;

  @include media(mobile-m) {
    font-size: 16px;
  }
}

.subtext {
  font-weight: var(--fw-500);
  font-size: 22px;
  line-height: 1.45;
  text-align: center;
  color: var(--color-green-400);
  opacity: 0.6;

  @include media(mobile-l) {
    font-size: 18px;
  }
}

@use "../utils/functions/"as *;
@use "../utils/mixins"as *;

.policy {
  padding: rem(60) 0;
  letter-spacing: 0.02em;

  &__content {
    &:not(:last-child) {
      margin-bottom: rem(48);
    }
  }

  h1 {
    margin-bottom: rem(60);
    text-align: center;
  }

  h2 {
    text-align: left;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  li {
    padding-left: 10px;
    letter-spacing: 0.02em;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &::marker {
      content: "✓";
      color: var(--color-green-900);
    }
  }

  p,
  li {
    opacity: 0.6;
    color: var(--color-green-900);

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
    opacity: 1;
    color: var(--color-black);
  }

  @include media(mobile-l) {
    &__content {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.step {
  &__content {
    margin: 0 auto rem(52);
    width: 100%;
    max-width: 854px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 822px;
    gap: 24px;
  }

  &__list {
    width: 100%;
    max-width: 293px;
  }

  &__item {
    display: flex;
    gap: 16px;

    &::before {
      content: "";
      position: relative;
      display: block;
      align-self: flex-start;
      width: 48px;
      height: 48px;
      background-image: url("../images/icons/practices.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
      aspect-ratio: 1/1;
    }

    &:not(:last-child) {
      margin-bottom: rem(64);
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: -0.01em;
    }
  }

  &__image {
    overflow: hidden;
    border-radius: var(--radius-main);

    img {
      height: 100%;
    }
  }

  .subtext {
    text-align: left;
  }

  h2 {
    margin-bottom: 18px;
  }

  @include media(desktop) {
    .subtext {
      text-align: center;
    }
  }

  @include media(tablet-wide) {
    &__item {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__inner {
      flex-direction: column;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      max-width: 100%;
    }

    &__item {
      &:last-child {
        grid-column: 2 span;
      }
    }

    &__image {
      img {
        width: 100%;
        aspect-ratio: 18/9;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}

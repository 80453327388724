@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: 36px;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 36px;

      @include media(tablet-wide) {
        margin-right: 15px;
      }
    }
  }

  &__link {
    position: relative;
    color: var(--color-green-900);
    font-weight: var(--fw-500);
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: 0.01em;
    background-color: transparent;
    transition: color 0.3s linear;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background-color: currentcolor;
      transform: scale(0);
      transform-origin: left;
      transition: transform 0.3s linear;
    }

    &:hover {
      &::before {
        transform: scale(1);
      }
    }

    &.true {
      &::before {
        transform: scale(1);
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &--footer {
    margin-bottom: 24px;
    color: var(--color-white);
    #{$root}__list {
      margin: 0;
    }

    #{$root}__item {
      &:not(:last-child) {
        @include media(mobile-m) {
          margin: 0 0 16px 0;
        }
      }
    }

    #{$root}__link {
      color: var(--color-white);
    }
  }

  @include media(tablet-wide) {
    &__list {
      margin-right: 16px;
    }
  }

  @include media(tablet) {
    &__list {
      margin-right: 0;
    }
  }

  @include media(mobile-l) {
    &--footer {
      flex-direction: column;
      gap: 24px;
    }
  }

  @include media(mobile-l) {
    &--footer {
      #{$root}__link {
        font-size: 16px;
      }
    }
  }

  @include media(mobile-m) {
    &--footer {
      #{$root}__list {
        flex-direction: column;
      }
    }
  }
}

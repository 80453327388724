@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.advantages {
  padding: rem(118) 0;

  &__content {
    margin: 0 auto rem(48);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto rem(48);
    width: 100%;
    max-width: 927px;
    gap: 24px;
  }

  &__item {
    &::before {
      content: "";
      position: relative;
      display: block;
      margin-bottom: 15px;
      width: 48px;
      height: 48px;
      background-image: url("../images/icons/locally.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    h3 {
      margin-bottom: 10px;
    }

    p {
      font-size: 12px;
      opacity: 0.4;
    }

    &--locally {
      &::before {
        background-image: url("../images/icons/locally.svg");
      }
    }

    &--organic {
      &::before {
        background-image: url("../images/icons/organic.svg");
      }
    }

    &--practices {
      &::before {
        background-image: url("../images/icons/practices.svg");
      }
    }
  }

  &__desc {
    text-align: center;
  }

  h2 {
    margin-bottom: 21px;
  }

  @include media(tablet) {
    padding: 30px 0;
  }

  @include media(mobile-l) {
    &__list,
    &__content {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
      max-width: 100%;
    }
  }
}

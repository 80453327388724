/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

:root {
  --content-width: 1244px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "DM Sans", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  //
  --color-white: #fff;
  --color-black: #000;
  --color-green-10: #eefff3;
  --color-green-100: #C4EED0;
  --color-green-400: #2fb945;
  --color-green-900: #043a2a;
  --color-blue-800: #073E57;
  --color-blue-900: #151439;

  //  radius
  --radius-main: 8px;
}

@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.article {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--color-white);
  }

  h3 {
    margin-bottom: 8px;
    opacity: 0.8;
  }

  p {
    margin-bottom: 8px;
    font-size: 12px;
    color: var(--color-green-900);
    opacity: 0.4;
  }

  a {
    margin-top: auto;
    width: fit-content;
    font-size: 14px;
    line-height: 1.43;
    text-decoration: underline;
    text-decoration-color: currentColor;
    color: var(--color-green-900);
    transition: color 0.4s linear;
    text-underline-offset: 2px;

    &:hover {
      color: var(--color-green-400);
    }
  }

  img {
    margin-bottom: 16px;
    border-radius: var(--radius-main);
    width: 100%;
  }

  span {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 1.5;
    color: var(--color-green-400);
    opacity: 0.8;
  }

  @include media(tablet) {
    &__content {
      height: fit-content;
    }

    h3 {
      min-height: 35px;
      max-width: 100%;
    }
  }

  @include media(mobile-m) {
    img {
      aspect-ratio: 18/9;
    }
  }
}

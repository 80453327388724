@import "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap";
:root {
  --content-width: 1244px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "DM Sans", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-black: #000;
  --color-green-10: #eefff3;
  --color-green-100: #c4eed0;
  --color-green-400: #2fb945;
  --color-green-900: #043a2a;
  --color-blue-800: #073e57;
  --color-blue-900: #151439;
  --radius-main: 8px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-blue-900);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section, .background {
  padding: 6.125rem 0;
}

@media only screen and (max-width: 992px) {
  .section, .background {
    padding: 30px 0;
  }
}

.background {
  background-color: var(--color-green-10);
}

.container, .wrapper {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.wrapper {
  max-width: 1064px;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.logo {
  width: 100%;
  max-width: 210px;
  margin-right: 9.375rem;
}

@media only screen and (max-width: 1180px) {
  .logo {
    margin-right: 16px;
  }
}

.is-scrolling {
  scrollbar-color: var(--color-green-400) var(--color-white);
  scrollbar-width: thin;
}

.is-scrolling::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.is-scrolling::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 10px;
}

.is-scrolling::-webkit-scrollbar-thumb {
  background-color: var(--color-green-400);
  border-radius: 10px;
}

@media only screen and (max-width: 1180px) {
  .is-scrolling {
    padding: 15px;
    overflow-y: scroll;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-700);
  letter-spacing: .02em;
  color: var(--color-green-900);
  margin-bottom: 20px;
  font-size: clamp(1.875rem, 1.2917rem + 2.5926vi, 3.625rem);
  line-height: 1.21;
  overflow: hidden;
}

h1 span, h2 span, h3 span {
  color: var(--color-green-400);
  display: inline-block;
}

h2 {
  letter-spacing: .03em;
  text-align: center;
  margin-bottom: 16px;
  font-size: clamp(1.5rem, 1.125rem + 1.6667vi, 2.625rem);
  line-height: 1.3;
}

h3 {
  font-weight: var(--fw-400);
  letter-spacing: 0;
  font-size: 16px;
  line-height: 1.25;
}

p {
  opacity: .4;
}

@media only screen and (max-width: 576px) {
  p {
    font-size: 16px;
  }
}

.subtext {
  font-weight: var(--fw-500);
  text-align: center;
  color: var(--color-green-400);
  opacity: .6;
  font-size: 22px;
  line-height: 1.45;
}

@media only screen and (max-width: 768px) {
  .subtext {
    font-size: 18px;
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-green-100);
  padding: 23px 0;
  transition-property: transform;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

@media only screen and (max-width: 992px) {
  .header {
    padding: 16px 0;
  }

  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.3);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-green-100);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }

  .header .nav__item {
    margin-bottom: 30px;
  }

  .header .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .header .nav__link {
    font-size: 18px;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin-right: 36px;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 36px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 15px;
  }
}

.nav__link {
  color: var(--color-green-900);
  font-weight: var(--fw-500);
  letter-spacing: .01em;
  background-color: #0000;
  font-size: 18px;
  line-height: 1.44;
  transition: color .3s linear;
  position: relative;
}

.nav__link:before {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 0;
  background-color: currentColor;
  transition: transform .3s linear;
  position: absolute;
  bottom: -2px;
  left: 0;
  transform: scale(0);
}

.nav__link:hover:before, .nav__link.true:before {
  transform: scale(1);
}

.nav__btns {
  align-items: center;
  gap: 16px;
  display: flex;
}

.nav--footer {
  color: var(--color-white);
  margin-bottom: 24px;
}

.nav--footer .nav__list {
  margin: 0;
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__item:not(:last-child) {
    margin: 0 0 16px;
  }
}

.nav--footer .nav__link {
  color: var(--color-white);
}

@media only screen and (max-width: 1180px) {
  .nav__list {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__list {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .nav--footer {
    flex-direction: column;
    gap: 24px;
  }

  .nav--footer .nav__link {
    font-size: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }
}

[class].btn {
  width: 100%;
  height: max-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: var(--fw-500);
  color: var(--color-white);
  background-color: var(--color-green-400);
  border: 1px solid #0000;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  font-size: 18px;
  line-height: 1.44;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-green-400);
  color: var(--color-green-400);
  background-color: var(--color-white);
}

[class].btn.formsapp-button {
  margin: 0;
  font-family: var(--font-family-primary) !important;
}

[class].btn--reverse {
  border-color: var(--color-green-400);
  font-weight: var(--fw-400);
  color: var(--color-green-400);
  background-color: var(--color-white);
  padding: 13px 32px;
  line-height: 1.11;
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

[class].btn--filter {
  white-space: nowrap;
  color: var(--color-green-400);
  background-color: #0000;
  padding: 0;
  font-size: 15px;
  line-height: 1.47;
}

[class].btn--filter:hover, [class].btn--filter:focus, [class].btn--filter.active {
  color: var(--color-green-900);
  border-color: #0000;
}

[class].btn--request {
  color: var(--color-white) !important;
  background-color: var(--color-green-400) !important;
}

[class].btn--request:hover, [class].btn--request:focus {
  border-color: var(--color-green-400);
  color: var(--color-green-400) !important;
  background-color: var(--color-white) !important;
}

[class].btn--sign {
  color: var(--color-green-900);
  background-color: var(--color-white);
}

[class].btn--sign:hover, [class].btn--sign:focus {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-green-900);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  letter-spacing: .02em;
  padding: 3.75rem 0;
}

.policy__content:not(:last-child) {
  margin-bottom: 3rem;
}

.policy h1 {
  text-align: center;
  margin-bottom: 3.75rem;
}

.policy h2 {
  text-align: left;
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  letter-spacing: .02em;
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  color: var(--color-green-900);
}

.policy p, .policy li {
  opacity: .6;
  color: var(--color-green-900);
}

.policy p:not(:last-child), .policy li:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  opacity: 1;
  color: var(--color-black);
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .policy__content:not(:last-child) {
    margin-bottom: 24px;
  }
}

.footer {
  color: var(--color-white);
  background-color: var(--color-green-900);
  padding: 3.0625rem 0;
}

.footer__bottom {
  opacity: .6;
  justify-content: space-between;
  display: flex;
}

.footer__bottom p {
  line-height: 1.1;
}

.footer__list {
  gap: 24px;
  display: flex;
}

.footer__link {
  font-size: 14px;
}

.footer .logo {
  margin-right: 16px;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 30px 0;
  }

  .footer__bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .footer__bottom p {
    text-align: center;
  }

  .footer .logo {
    margin-right: 0;
  }
}

.contacts {
  padding: 7.375rem 0;
}

.contacts__wrapper {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.contacts__image {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.contacts__content {
  z-index: 2;
  width: 100%;
  max-width: 716px;
}

.contacts__form {
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.contacts__form label {
  width: 100%;
}

.contacts__form input {
  width: 100%;
  max-width: 100%;
  font-weight: var(--fw-700);
  border: 1px solid #3232325c;
  border-radius: 10px;
  padding: 16px 20px 16px 55px;
  font-size: 14px;
  line-height: 1.86;
  position: relative;
}

.contacts__form input[type="email"], .contacts__form input[type="text"] {
  background-image: url("../images/icons/email.svg");
  background-position: 19px 17px;
  background-repeat: no-repeat;
}

.contacts__form input[type="text"] {
  background-image: url("../images/icons/account.svg");
}

.contacts__link {
  align-items: center;
  gap: 12px;
  margin-bottom: 22px;
  font-size: 14px;
  line-height: 1.85;
  display: flex;
}

.contacts__link:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/email.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.contacts h2 {
  text-align: left;
  color: var(--color-blue-800);
  margin-bottom: 3rem;
  font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
  line-height: 1.06;
}

.contacts .btn {
  padding: 16px 80px;
  line-height: 1.22;
}

@media only screen and (max-width: 992px) {
  .contacts {
    padding: 30px 0;
  }

  .contacts h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .contacts__wrapper {
    flex-direction: column;
  }

  .contacts__wrapper .btn {
    max-width: 100%;
  }

  .contacts__image {
    width: 100%;
    aspect-ratio: 18 / 9;
  }
}

.hero {
  padding: 7.375rem 0;
}

.hero__inner {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.hero__image {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.hero__image img {
  width: 100%;
}

.hero__content {
  width: 100%;
  max-width: 610px;
  translate: 0 -44px;
}

.hero .subtext {
  text-align: left;
  color: var(--color-blue-900);
}

.hero--home {
  padding: 4rem 0;
}

.hero--initiatives .hero__content {
  translate: 0;
}

@media only screen and (max-width: 992px) {
  .hero {
    padding: 40px 0;
  }

  .hero__inner {
    flex-direction: column;
  }

  .hero__content {
    max-width: 100%;
    translate: 0;
  }

  .hero__image {
    width: 100%;
  }

  .hero__image img {
    object-position: center;
    aspect-ratio: 18 / 9;
  }

  .hero h1 {
    text-align: center;
  }

  .hero--reverse .hero__inner {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 576px) {
  .hero {
    padding: 20px 0;
  }

  .hero .btn {
    max-width: 100%;
  }
}

.filter {
  padding: 6.0625rem 0;
}

.filter__desc {
  text-align: center;
  margin: 0 auto 4rem;
}

.filter__nav {
  justify-content: space-between;
  align-items: center;
  gap: 23px;
  margin: 0 auto 3rem;
  display: flex;
}

.filter__grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 36px 24px;
  display: grid;
}

.filter h2 {
  margin-bottom: 13px;
  line-height: 1.3;
}

@media only screen and (max-width: 1180px) {
  .filter__nav {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 992px) {
  .filter {
    padding: 30px 0;
  }

  .filter__nav {
    margin-bottom: 30px;
  }

  .filter__grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  .filter__desc {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .filter__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .filter__grid {
    grid-template-columns: 1fr;
  }
}

.article {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.article__content {
  height: 100%;
  background-color: var(--color-white);
  flex-direction: column;
  display: flex;
}

.article h3 {
  opacity: .8;
  margin-bottom: 8px;
}

.article p {
  color: var(--color-green-900);
  opacity: .4;
  margin-bottom: 8px;
  font-size: 12px;
}

.article a {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--color-green-900);
  text-underline-offset: 2px;
  margin-top: auto;
  font-size: 14px;
  line-height: 1.43;
  text-decoration: underline;
  transition: color .4s linear;
}

.article a:hover {
  color: var(--color-green-400);
}

.article img {
  border-radius: var(--radius-main);
  width: 100%;
  margin-bottom: 16px;
}

.article span {
  color: var(--color-green-400);
  opacity: .8;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.5;
  display: block;
}

@media only screen and (max-width: 992px) {
  .article__content {
    height: -moz-fit-content;
    height: fit-content;
  }

  .article h3 {
    min-height: 35px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .article img {
    aspect-ratio: 18 / 9;
  }
}

.product {
  padding: 7.375rem 0;
}

.product__inner {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.product__content {
  width: 100%;
  max-width: 504px;
  align-self: flex-start;
}

.product__link {
  font-weight: var(--fw-500);
  color: var(--color-green-900);
  align-items: center;
  gap: 8px;
  margin-bottom: 17px;
  font-size: 18px;
  line-height: 1;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .4s linear, text-decoration-color .4s linear;
  display: flex;
}

.product__link:before {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("../images/icons/arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.product__link:hover {
  -webkit-text-decoration-color: var(--color-green-900);
  text-decoration-color: var(--color-green-900);
}

.product__categories {
  color: var(--color-green-400);
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.5;
  display: block;
}

.product__list li {
  font-weight: var(--fw-700);
  letter-spacing: .1em;
  color: #043a2a66;
  align-items: center;
  gap: 8px;
  padding-left: 30px;
  font-size: clamp(16px, .9227rem + .3636vi, 20px);
  line-height: 1.3;
  display: flex;
  position: relative;
}

.product__list li:before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #043a2a66;
  border-radius: 50%;
  position: absolute;
  left: 12px;
}

.product__list li span {
  color: #043a2a99;
}

.product__list li:not(:last-child) {
  margin-bottom: 8px;
}

.product h1 {
  letter-spacing: .03em;
  margin-bottom: 16px;
  font-size: clamp(1.5rem, 1.125rem + 1.6667vi, 2.625rem);
  line-height: 1.24;
}

.product p {
  margin-bottom: 23px;
  line-height: 1.5;
}

.product img {
  border-radius: var(--radius-main);
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .product {
    padding: 40px 0;
  }

  .product__inner {
    flex-direction: column-reverse;
  }

  .product__content {
    max-width: 100%;
  }

  .product img {
    width: 100%;
    aspect-ratio: 18 / 9;
  }
}

.story__content {
  margin: 0 auto 3rem;
}

.story__image {
  border-radius: var(--radius-main);
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 1.375rem;
  overflow: hidden;
}

.story__desc {
  width: 100%;
  max-width: 1010px;
  text-align: center;
  margin: 0 auto 3.25rem;
  line-height: 1.62;
}

.story--about .story__content {
  width: 100%;
  max-width: 775px;
}

.story--about .story__desc {
  max-width: 1032px;
  text-align: left;
  margin-bottom: 52px;
}

.story--about .story__desc p:not(:last-of-type) {
  margin-bottom: 10px;
}

.story--about .story__image {
  margin-bottom: 0;
}

.story--initiatives .story__content {
  margin-bottom: 3.25rem;
}

.story--initiatives .story__desc {
  margin-bottom: 0;
}

.story h2 {
  margin-bottom: 11px;
}

.story .btn {
  background-color: #0000;
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .story__content, .story__desc {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .story .btn {
    max-width: 100%;
  }
}

.featured__desc {
  margin: 0 auto 4rem;
}

.featured__desc .subtext {
  text-align: center;
  color: var(--color-green-400);
}

.featured__list {
  grid-template-columns: repeat(4, 1fr);
  gap: 37px 24px;
  margin-bottom: 4rem;
  display: grid;
}

.featured h2 {
  margin-bottom: 13px;
}

.featured .btn {
  margin-inline: auto;
}

@media only screen and (max-width: 992px) {
  .featured__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  .featured__desc, .featured__list {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .featured__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .featured__list {
    grid-template-columns: 1fr;
  }

  .featured .btn {
    max-width: 100%;
  }
}

.advantages {
  padding: 7.375rem 0;
}

.advantages__content {
  margin: 0 auto 3rem;
}

.advantages__list {
  width: 100%;
  max-width: 927px;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: 0 auto 3rem;
  display: grid;
}

.advantages__item:before {
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("../images/icons/locally.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 15px;
  display: block;
  position: relative;
}

.advantages__item h3 {
  margin-bottom: 10px;
}

.advantages__item p {
  opacity: .4;
  font-size: 12px;
}

.advantages__item--locally:before {
  background-image: url("../images/icons/locally.svg");
}

.advantages__item--organic:before {
  background-image: url("../images/icons/organic.svg");
}

.advantages__item--practices:before {
  background-image: url("../images/icons/practices.svg");
}

.advantages__desc {
  text-align: center;
}

.advantages h2 {
  margin-bottom: 21px;
}

@media only screen and (max-width: 992px) {
  .advantages {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 768px) {
  .advantages__list, .advantages__content {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .advantages__list {
    max-width: 100%;
    grid-template-columns: 1fr;
  }
}

.planet__content {
  margin: 0 auto 3rem;
}

.planet__list {
  width: 100%;
  max-width: 1032px;
  grid-template-columns: repeat(3, 1fr);
  gap: 77px;
  margin: 0 auto;
  display: grid;
}

.planet__item img {
  border-radius: var(--radius-main);
  margin-bottom: 16px;
}

.planet__item h3 {
  margin-bottom: 8px;
}

.planet__item p {
  color: var(--color-green-900);
  font-size: 12px;
  line-height: 1.5;
}

.planet h2 {
  margin-bottom: 22px;
}

@media only screen and (max-width: 1180px) {
  .planet__list {
    gap: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .planet__content {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .planet__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .planet__item img {
    width: 100%;
    object-position: center;
    aspect-ratio: 18 / 9;
  }

  .planet__item:last-child {
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 576px) {
  .planet__list {
    grid-template-columns: 1fr;
  }

  .planet__item:last-child {
    grid-column: auto;
  }
}

.solution {
  padding: 7.375rem 0;
}

.solution__wrapper {
  gap: 24px;
  display: flex;
}

.solution__content {
  width: 100%;
  max-width: 494px;
}

.solution__image img {
  border-radius: var(--radius-main);
  width: 100%;
}

.solution h2 {
  text-align: left;
  margin-bottom: 6px;
}

.solution p {
  line-height: 1.62;
}

.solution .subtext {
  text-align: left;
  margin-bottom: 13px;
}

@media only screen and (max-width: 992px) {
  .solution {
    padding: 30px 0;
  }

  .solution__wrapper {
    flex-direction: column;
  }

  .solution__content {
    max-width: 100%;
  }

  .solution__content h2, .solution__content .subtext {
    text-align: center;
  }

  .solution__image img {
    aspect-ratio: 18 / 9;
  }
}

.farmers__content {
  width: 100%;
  max-width: 682px;
  text-align: center;
  margin: 0 auto 2.9375rem;
}

.farmers__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 4.8125rem;
  display: grid;
}

.farmers__item h3 {
  margin-bottom: 8px;
}

.farmers__item p {
  color: #043a2a99;
  font-size: 12px;
}

.farmers__item p:first-of-type {
  margin-bottom: 8px;
}

.farmers__item span {
  color: var(--color-green-900);
}

.farmers__item img {
  border-radius: var(--radius-main);
  margin-bottom: 16px;
}

.farmers h2 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .farmers__list {
    gap: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .farmers__content {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .farmers__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .farmers__item img {
    width: 100%;
    object-position: top;
    aspect-ratio: 18 / 9;
  }

  .farmers__item:last-child {
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 576px) {
  .farmers__list {
    grid-template-columns: 1fr;
  }

  .farmers__item:last-child {
    grid-column: auto;
  }
}

.step__content {
  width: 100%;
  max-width: 854px;
  margin: 0 auto 3.25rem;
}

.step__inner {
  width: 100%;
  max-width: 822px;
  justify-content: space-between;
  gap: 24px;
  margin: 0 auto;
  display: flex;
}

.step__list {
  width: 100%;
  max-width: 293px;
}

.step__item {
  gap: 16px;
  display: flex;
}

.step__item:before {
  content: "";
  width: 48px;
  height: 48px;
  aspect-ratio: 1 / 1;
  background-image: url("../images/icons/practices.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: flex-start;
  display: block;
  position: relative;
}

.step__item:not(:last-child) {
  margin-bottom: 4rem;
}

.step__desc {
  flex-direction: column;
  display: flex;
}

.step__desc h3 {
  margin-bottom: 8px;
}

.step__desc p {
  letter-spacing: -.01em;
  font-size: 12px;
  line-height: 1.5;
}

.step__image {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.step__image img {
  height: 100%;
}

.step .subtext {
  text-align: left;
}

.step h2 {
  margin-bottom: 18px;
}

@media only screen and (max-width: 1280px) {
  .step .subtext {
    text-align: center;
  }
}

@media only screen and (max-width: 1180px) {
  .step__item:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .step__content {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .step__inner {
    flex-direction: column;
  }

  .step__list {
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .step__item:last-child {
    grid-column: 2 span;
  }

  .step__image img {
    width: 100%;
    aspect-ratio: 18 / 9;
  }
}

@media only screen and (max-width: 576px) {
  .step__list {
    grid-template-columns: 1fr;
  }

  .step__item:last-child {
    grid-column: auto;
  }
}

/*# sourceMappingURL=main.css.map */

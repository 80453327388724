@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.contacts {
  padding: rem(118) 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  &__image {
    overflow: hidden;
    border-radius: var(--radius-main);
  }

  &__content {
    z-index: 2;
    width: 100%;
    max-width: 716px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    gap: 25px;

    label {
      width: 100%;
    }

    input {
      position: relative;
      border: 1px solid rgba(50, 50, 50, 0.36);
      border-radius: 10px;
      padding: 16px 20px 16px 55px;
      width: 100%;
      max-width: 100%;
      font-weight: var(--fw-700);
      font-size: 14px;
      line-height: 1.86;
    }

    input[type="email"],
    input[type="text"] {
      background-image: url("../images/icons/email.svg");
      background-position: 19px 17px;
      background-repeat: no-repeat;
    }

    input[type="text"] {
      background-image: url("../images/icons/account.svg");
    }
  }

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 1.85;
    gap: 12px;

    &::before {
      content: "";
      position: relative;
      width: 24px;
      height: 24px;
      background-image: url("../images/icons/email.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  h2 {
    margin-bottom: rem(48);
    font-size: clamp(1.5rem, 1rem + 2.2222vi, 3rem);
    line-height: 1.06;
    text-align: left;
    color: var(--color-blue-800);
  }

  .btn {
    padding: 16px 80px;
    line-height: 1.22;
  }

  @include media(tablet) {
    padding: 30px 0;

    h2 {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__wrapper {
      flex-direction: column;

      .btn {
        max-width: 100%;
      }
    }

    &__image {
      width: 100%;
      aspect-ratio: 18/9;
    }
  }
}

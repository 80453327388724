@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.planet {
  &__content {
    margin: 0 auto rem(48);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    width: 100%;
    max-width: 1032px;
    gap: 77px;
  }

  &__item {
    img {
      margin-bottom: 16px;
      border-radius: var(--radius-main);
    }

    h3 {
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
      line-height: 1.5;
      color: var(--color-green-900);
    }
  }

  h2 {
    margin-bottom: 22px;
  }

  @include media(tablet-wide) {
    &__list {
      gap: 24px;
    }
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      img {
        width: 100%;
        object-position: center;
        aspect-ratio: 18/9;
      }

      &:last-child {
        grid-column: 2 span;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}

@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.filter {
  $root: &;

  padding: rem(97) 0;

  &__desc {
    margin: 0 auto rem(64);
    text-align: center;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto rem(48);
    gap: 23px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 36px 24px;
  }

  h2 {
    margin-bottom: 13px;
    line-height: 1.3;
  }

  @include media(tablet-wide) {
    &__nav {
      justify-content: flex-start;
    }
  }

  @include media(tablet) {
    padding: 30px 0;
    
    &__nav {
      margin-bottom: 30px;
    }

    &__grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }

    &__desc {
      margin-bottom: 25px;
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}

@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.solution {
  padding: rem(118) 0;

  &__wrapper {
    display: flex;
    gap: 24px;
  }

  &__content {
    width: 100%;
    max-width: 494px;
  }

  &__image {
    img {
      border-radius: var(--radius-main);
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 6px;
    text-align: left;
  }

  p {
    line-height: 1.62;
  }

  .subtext {
    margin-bottom: 13px;
    text-align: left;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__wrapper {
      flex-direction: column;
    }

    &__content {
      max-width: 100%;

      h2,
      .subtext {
        text-align: center;
      }
    }

    &__image {
      img {
        aspect-ratio: 18/9;
      }
    }
  }
}

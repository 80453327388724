@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  $root: &;

  padding: rem(118) 0;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  &__image {
    overflow: hidden;
    border-radius: var(--radius-main);

    img {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    max-width: 610px;
    translate: 0 -44px;
  }

  .subtext {
    text-align: left;
    color: var(--color-blue-900);
  }

  &--home {
    padding: rem(64) 0;
  }

  &--initiatives {
    #{$root}__content {
      translate: 0;
    }
  }

  @include media(tablet) {
    padding: 40px 0;

    &__inner {
      flex-direction: column;
    }

    &__content {
      max-width: 100%;
      translate: 0;
    }

    &__image {
      width: 100%;

      img {
        object-position: center;
        aspect-ratio: 18/9;
      }
    }

    h1 {
      text-align: center;
    }

    &--reverse {
      #{$root}__inner {
        flex-direction: column-reverse;
      }
    }
  }

  @include media(mobile-m) {
    padding: 20px 0;
    
    .btn {
      max-width: 100%;
    }
  }
}

@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.story {
  $root: &;

  &__content {
    margin: 0 auto rem(48);
  }

  &__image {
    overflow: hidden;
    margin: 0 auto rem(22);
    border-radius: var(--radius-main);
    width: fit-content;
  }

  &__desc {
    margin: 0 auto rem(52);
    width: 100%;
    max-width: 1010px;
    line-height: 1.62;
    text-align: center;
  }

  &--about {
    #{$root}__content {
      width: 100%;
      max-width: 775px;
    }

    #{$root}__desc {
      margin-bottom: 52px;
      max-width: 1032px;
      text-align: left;

      p {
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }

    #{$root}__image {
      margin-bottom: 0;
    }
  }

  &--initiatives {
    #{$root}__content {
      margin-bottom: rem(52);
    }
    #{$root}__desc {
      margin-bottom: 0;
    }
  }

  h2 {
    margin-bottom: 11px;
  }

  .btn {
    margin: 0 auto;
    background-color: transparent;
  }

  @include media(tablet) {
    &__content,
    &__desc {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-m) {
    .btn {
      max-width: 100%;
    }
  }
}

@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.farmers {
  &__content {
    margin: 0 auto rem(47);
    width: 100%;
    max-width: 682px;
    text-align: center;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(77);
  }

  &__item {
    h3 {
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
      color: rgba(4, 58, 42, 0.6);

      &:first-of-type {
        margin-bottom: 8px;
      }
    }

    span {
      color: var(--color-green-900);
    }

    img {
      margin-bottom: 16px;
      border-radius: var(--radius-main);
    }
  }

  h2 {
    margin-bottom: 20px;
  }

  @include media(tablet-wide) {
    &__list {
      gap: 24px;
    }
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      img {
        width: 100%;
        object-position: top;
        aspect-ratio: 18/9;
      }

      &:last-child {
        grid-column: 2 span;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}

@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.footer {
  padding: rem(49) 0;
  color: var(--color-white);
  background-color: var(--color-green-900);

  &__bottom {
    display: flex;
    justify-content: space-between;
    opacity: 0.6;

    p {
      line-height: 1.1;
    }
  }

  &__list {
    display: flex;
    gap: 24px;
  }

  &__link {
    font-size: 14px;
  }

  .logo {
    margin-right: 16px;
  }

  @include media(mobile-l) {
    padding: 30px 0;

    &__bottom {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;

      p {
        text-align: center;
      }
    }

    .logo {
      margin-right: 0;
    }
  }
}

@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.product {
  padding: rem(118) 0;

  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  &__content {
    align-self: flex-start;
    width: 100%;
    max-width: 504px;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    font-weight: var(--fw-500);
    font-size: 18px;
    line-height: 1;
    text-decoration: underline;
    text-decoration-color: transparent;
    color: var(--color-green-900);
    transition: text-decoration-color 0.4s linear;
    gap: 8px;

    &::before {
      content: "";
      position: relative;
      width: 18px;
      height: 18px;
      background-image: url("../images/icons/arrow.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:hover {
      text-decoration-color: var(--color-green-900);
    }
  }

  &__categories {
    display: block;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 1.5;
    color: var(--color-green-400);
  }

  &__list {
    li {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 30px;
      font-weight: var(--fw-700);
      font-size: clamp(16px, 0.9227rem + 0.3636vi, 20px);
      line-height: 1.3;
      letter-spacing: 0.1em;
      color: rgba(4, 58, 42, 0.4);
      gap: 8px;

      &::before {
        content: "";
        position: absolute;
        left: 12px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: rgba(4, 58, 42, 0.4);
      }

      span {
        color: rgba(4, 58, 42, 0.6);
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  h1 {
    margin-bottom: 16px;
    font-size: clamp(1.5rem, 1.125rem + 1.6667vi, 2.625rem);
    line-height: 1.24;
    letter-spacing: 0.03em;
  }

  p {
    margin-bottom: 23px;
    line-height: 1.5;
  }

  img {
    overflow: hidden;
    border-radius: var(--radius-main);
  }

  @include media(tablet) {
    padding: 40px 0;

    &__inner {
      flex-direction: column-reverse;
    }

    &__content {
      max-width: 100%;
    }

    img {
      width: 100%;
      aspect-ratio: 18/9;
    }
  }
}
